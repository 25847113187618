import {AppActions} from "../../Helpers/GlobalTypes";
import {LOADING} from "../Global/Global";

function loadingAction (isLoading: boolean): AppActions {
    return {
        type: LOADING,
        payload: isLoading
    }
}


export {
    loadingAction,
}