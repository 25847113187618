import {UserInterface} from "../../Helpers/types/actions/User";
import {AppActions} from "../../Helpers/GlobalTypes";
import {LOGIN_USER, LOGOUT_USER} from "../User/User";

function loginUserAction(user: UserInterface): AppActions {
    return {
        type: LOGIN_USER,
        payload: {
            email: user.email,
            token: user.token,
            environment: user.environment,
        }
    }
}

function logoutUserAction(): AppActions {
    return {
        type: LOGOUT_USER,
        payload: {
            email: null,
            token: null,
            environment: null,
        }
    }
}

export {
    loginUserAction,
    logoutUserAction,
}