import {LOGIN_USER, LOGOUT_USER} from "../../actions/User/User";
import {UserActionTypes, UserState} from "../../Helpers/types/actions/User";


const initialState: UserState = {
    user: {
        email: null,
        token: null,
        environment: null,
    }
}

export default function user(state = initialState, action: UserActionTypes): UserState {
    switch (action.type) {
        case LOGIN_USER:
            return {
                ...state,
                user: {
                    email: action.payload.email,
                    token: action.payload.token,
                    environment: action.payload.environment,
                }
            };
        case LOGOUT_USER:
            return {
                ...state,
                user: {
                    email: null,
                    token: null,
                    environment: null
                },
            };

        default:
            return state;
    }

}