import axios, {AxiosResponse} from "axios";
import {ProcessEnv, } from "../../Helpers/GlobalTypes";
import {EmbedLink} from "../../Helpers/types/actions/IFrame";
import {getEmbedLinkAction} from "../ActionCreators/IFrame";
import {loadingAction} from "../ActionCreators/Global";

export const GET_EMBED_LINK: string = 'GET_EMBED_LINK';




/**
 * Action to get selected link that is displayed based on choice clicked from menu drawer.
 * */
export const getEmbedLink = async ({user, embedObject, dispatch}: EmbedLink): Promise<void> => {
    const API: ProcessEnv = process.env.REACT_APP_API;
    const headers: object = {
        'AUTHORIZATION': user.token,
        'ENVIRONMENT': user.environment
    };

    try {        //TODO: Change to user
        if (user.token) {
            dispatch(loadingAction(true));
            let data: AxiosResponse = await axios.post(`${API}/v1/get_embed_link`, {
                "email": user.email,
                "embed_code": embedObject.EMBED_CODE
            }, {headers: headers});
            if (data) {
                const link: string = data.data.link;
                // getEmbedLinkDispatch(link);
                dispatch(getEmbedLinkAction(link, embedObject.EMBED_NAME))
            }
        }
    } catch (error) {
        dispatch(loadingAction(false));
        console.log("Error", error)
    }
}

