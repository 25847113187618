import {useSelector} from "react-redux";
import {CircularProgress, Dialog, DialogContent, Grid} from "@material-ui/core";
import React, {FC} from "react";
import {ThemeProvider} from "@material-ui/styles";
import {MakeStyles} from "./GlobalTypes";
import {makeStyles} from "@material-ui/core/styles";
import LoadingStyle, {dialogOverride} from "../assets/ts/Helpers/LoadingStyle";
import {RootState} from "../store";

/**
 * Component to overlay a loading dialog.
 * */
const LoadingDialog: FC = (): JSX.Element => {
    const classes: MakeStyles = makeStyles(theme => (LoadingStyle(theme)))();

    const {
        global: {
            isLoading
        }
    }: RootState = useSelector((state: RootState) => state);

    return (
        <ThemeProvider theme={dialogOverride}>
            <Dialog open={isLoading} className={classes.dialog}>

                <DialogContent className={classes.dialogContent}>
                    <Grid container justify={"center"}>
                        <CircularProgress size="8rem"/>
                        <div className={classes.loadingDiv}>
                            Loading
                        </div>
                    </Grid>
                </DialogContent>
            </Dialog>
        </ThemeProvider>
    )
};

export {
    LoadingDialog
}