import React from 'react';
import './App.css';
import {Main} from "./Main/Main"
import axios from "axios";
import history from "./history";
import {logoutUser} from "./actions/User/User";
import {Dispatch} from "redux";
import {AppActions} from "./Helpers/GlobalTypes";
import {useDispatch} from "react-redux";
import cookie from 'react-cookies'
import {loginUserAction, logoutUserAction} from './actions/ActionCreators/User';

function App() {
    const dispatch: Dispatch<AppActions> = useDispatch();
    /***
     *   Catch all 401's coming from the API calls and reset the users.
     */
        // console.info("", history.location.pathname);
    const API: string | undefined = process.env.REACT_APP_API;
    const paths: string = history.location.pathname;
    if (!paths.includes("token")) {
        const user = cookie.load('user') || {
            email: null,
            token: null,
            environment: null
        };

        dispatch(loginUserAction(user));
        if (user.token) {
            const headers = {
                'AUTHORIZATION': user.token,
                'ENVIRONMENT': user.environment
            };
            axios.post(API + "/auth/verify_token", {}, {
                headers: headers
            }).catch((error) => {
                dispatch(logoutUserAction());
                cookie.remove('user');
                window.location.href = '/pageNotFound';
            });
        }
    }

    axios.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        if (401 === error.response.status) {
            axios.defaults.headers.common["AUTHORIZATION"] = null;
            axios.defaults.headers.common["ENVIRONMENT"] = null;
            logoutUser(dispatch);
        } else {
            return Promise.reject(error);
        }
    });

    return (
        <div className="App">
            <Main history={history}/>
        </div>
    );
}

export default App;
