import {Theme} from "@material-ui/core";
import {iconTeal} from "../GlobalStyle";

const MenuBarStyle = (theme: Theme): any => ({
    appBarColor: {
        background: iconTeal
    },
    drawer: {
        cursor: 'default',
        '&:hover': {
            cursor: 'pointer',
            opacity: '75%',
        },
        borderBottom: `${iconTeal} 2px solid`,
        width: 250,
        height: 50,
    },
    drawerText: {
        color: "#000000",
        fontSize: 18,
        margin: 'auto'
    }
});

export default MenuBarStyle;