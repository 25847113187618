import {GET_EMBED_LINK} from "../IFrame/IFrame";
import {AppActions} from "../../Helpers/GlobalTypes";

function getEmbedLinkAction(link: string, title: string): AppActions{
    return {
        type: GET_EMBED_LINK,
        payload: {
            link,
            title
        }
    }
}

export {
    getEmbedLinkAction,
}