/**
 * Global styles
 * */

const backgroundBlue: string = "#013751" //background
const iconTeal = "#0FB9A3" //menuBar

export {
    backgroundBlue,
    iconTeal,
}