import {createStore, combineReducers, applyMiddleware, Store,} from 'redux';
// import {loadState, saveState} from "./persistedState"
import thunk, {ThunkMiddleware} from 'redux-thunk';


import menuBar from "./reducers/MenuBar/MenuBar";
import iframe from "./reducers/IFrame/IFrame";
import global from "./reducers/Global";
import user from "./reducers/User/User";
import {AppActions} from "./Helpers/GlobalTypes";

const reducer = combineReducers({
    menuBar,
    iframe,
    global,
    user,
});

/**
 * Creates a combined type of all reducers.
 * */
export type RootState = ReturnType<typeof reducer>;

// const persistedState: object | undefined = loadState();
const store: Store = createStore(reducer, applyMiddleware(thunk as ThunkMiddleware<RootState, AppActions>));

// store.subscribe(throttle(() => {
//     saveState({});
// }, 10));


export default store;