import React, {FC} from 'react';
import {Grid, Typography} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {AppActions, MakeStyles} from "../Helpers/GlobalTypes";
import {makeStyles} from "@material-ui/core/styles";
import IFrameStyle from "../assets/ts/IFrame/IFrameStyle";
import {LoadingDialog} from "../Helpers/Loading";
import {MenuBar} from "../MenuBar/MenuBar";
import {loading} from '../actions/Global/Global';
import {Dispatch} from "redux";
import {RootState} from "../store";

/**
 * Component to render an iframe under the menu bar.
 * */

const IFrame: FC = (): JSX.Element => {
        const appBarHeight: number = document.getElementById("appBarID")?.clientHeight || 60;
        const classes: MakeStyles = makeStyles(theme => (IFrameStyle(theme, appBarHeight)))();
        const {
            iframe: {
                link,
                title
            },
            menuBar: {
                embedArray,
            },
        }: RootState = useSelector((state: RootState) => state);
        const dispatch: Dispatch<AppActions> = useDispatch();
        return (
            <Grid container xs={12} className={classes.root}>
                {link ?
                    <Grid item xs={12}>
                        <MenuBar/>
                        <LoadingDialog/>
                        <iframe src={link}
                                title={title}
                                className={classes.iframe}
                                onLoad={() => loading({isLoading: false, dispatch})}
                        />
                    </Grid>
                    :
                <Grid item xs={12}>
                    <MenuBar/>
                    {embedArray &&
                    <Grid container item justify={"center"} alignItems={"center"} className={classes.selectionItem}>
                        {embedArray?.length > 0 ?
                            <Typography className={classes.selectionItem}>Please make a selection.</Typography>
                            :
                            <Typography className={classes.selectionItem}>Not authorized for any dashboards.</Typography>
                        }
                    </Grid>
                    }
                </Grid>
                }
            </Grid>
        );
    };

export
{
    IFrame
}
    ;
