import React from 'react';
import {useDispatch} from "react-redux";
import {loginUserFromToken, clearUserCredentials} from "../actions/User/User"
import {Dispatch} from "redux";
import {AppActions} from "../Helpers/GlobalTypes";

// import {errorMessageLogin} from "../actions/Error/Error";


interface TokenInterface {
    match: {
        params: {
            token: string,
            environment: string,
        }
    }
}

/***
 *
 * Takes in a token from the router to check against a user pool with
 *
 * @param props
 * @return {*}
 * @constructor
 */
export default function Token(props: TokenInterface): JSX.Element {
    const dispatch: Dispatch<AppActions> = useDispatch();
    /***
     * The token to check
     * @type {CancelToken | null | i}
     */
    const token: string = props.match.params.token;
    const environment: string = props.match.params.environment;
    /***
     * Will decode the base 64 encoding of the user object that contains the actual JWT and other user attributes.
     * @param token
     */
        // useEffect(() => {
        //     errorMessageLogin({
        //         errorMessage: false,
        //         errorMessageText: false,
        //     }, dispatch)
        // }, [dispatch])
    const parseToken = (token: string) => {
            try {
                const parsedData = JSON.parse(atob(token));
                loginUserFromToken({values: parsedData, dispatch, environment})
            } catch (err) {
                console.log("ERROR PARSING DATA");
                clearUserCredentials(dispatch)
            }

        };
    parseToken(token);
    return (
        <></>
    )
}