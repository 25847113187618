import {
    menuObject
} from "../../Helpers/types/actions/MenuBar";
import {SET_MENU_BAR} from "../MenuBar/MenuBar";
import {AppActions} from "../../Helpers/GlobalTypes";

function setMenuBarAction (menu: menuObject[]): AppActions {
    return {
        type: SET_MENU_BAR,
        payload: menu
    }
}

export {
    setMenuBarAction,
}