import {LOADING} from "../actions/Global/Global";
import {GlobalState, LoadingAction} from "../Helpers/types/actions/Global";

const initialState:GlobalState = {
    isLoading: true
};

export default function global(state = initialState, action: LoadingAction): GlobalState {
    switch (action.type) {
        case LOADING:
            return {
                ...state,
                isLoading: action.payload,
        };
        default:
            return state;
    }
}