/**
 * Actions used throughout the project.
 * */
import {loadingAction} from "../ActionCreators/Global";
import {IsLoading} from "../../Helpers/types/actions/Global";

export const LOADING: string = 'LOADING'


/**
 * Controls boolean state of loading dialog.
 * */
export const loading = ({isLoading, dispatch}: IsLoading): void => {
    // loadingDispatch(isLoading);
    dispatch(loadingAction(isLoading))
}