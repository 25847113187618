import {Theme} from "@material-ui/core";
import {backgroundBlue, iconTeal} from "../GlobalStyle";

const PageNotFoundStyle = (theme: Theme): any => ({
    root:{
        backgroundColor: backgroundBlue,
        height: window.innerHeight,
        width: '100%',
        display: 'fixed',
        color: '#FFFFFF',
        fontSize: 30,
    }
});

export default PageNotFoundStyle;