import axios, {AxiosResponse} from "axios";
import {
    menuObject, MenuBar
} from "../../Helpers/types/actions/MenuBar";

import {setMenuBarAction} from "../ActionCreators/MenuBar";
import {loadingAction} from "../ActionCreators/Global";
// import axios from "axios";

export const SET_MENU_BAR: string = 'SET_MENU_BAR';


/**
 * Action to get the data array for the menu bar.
 * @param dispatch
 * @param user
 * @returns {void}
 * */
export const getMenuBar = async ({user, link, dispatch}: MenuBar): Promise<void> => {
    const API: string | undefined = process.env.REACT_APP_API;

    const headers: object = {
        'AUTHORIZATION': user.token,
        'ENVIRONMENT': user.environment
    };

    try {
        if (user.token) {
            dispatch(loadingAction(true));

            let data: AxiosResponse = await axios.post(`${API}/v1/get_embed`, {
                "email": user.email
            }, {headers: headers});
            if (data) {
                const menu: menuObject[] = data.data.menu
                // const test: GetMenuBarAction = getMenuBar(data.data.menu)
                // const payload: GetMenuBarAction = {
                //     type: GET_MENU_BAR,
                //     payload: menu
                // }
                // dispatch(payload)
                dispatch(setMenuBarAction(menu))
                //
                if (!link) {
                    dispatch(loadingAction(false));
                    // getEmbedLink({user: user, embedObject: menu[0], dispatch});
                }
            }
        }

    } catch (error) {
        dispatch(loadingAction(false));
        console.log("Error", error)
    }

}

