import history from "../../history";
import axios from "axios";
import {UserInterface, UserInterfaceDispatch} from "../../Helpers/types/actions/User";
import {loginUserAction, logoutUserAction} from "../ActionCreators/User";
import {Dispatch} from "redux";
import {AppActions} from "../../Helpers/GlobalTypes";
import cookie from 'react-cookies'

export const LOGOUT_USER: string = 'LOGOUT_USER';
export const LOGIN_USER: string = 'LOGIN_USER';

export function logoutUser(dispatch: Dispatch<AppActions>): void {
    clearUserCredentials(dispatch);
    history.push('/pageNotFound')
}

/**
 * Call this to reset the user cookie and dispatch the LOGOUT_USER function
 *
 * @author Dylan Sadnick <dsadnick@edanalytics.org>
 */
export function clearUserCredentials(dispatch: Dispatch<AppActions>): void {


    axios.defaults.headers.common["AUTHORIZATION"] = null;
    axios.defaults.headers.common["ENVIRONMENT"] = null;
    // dispatch({
    //     type: LOGOUT_USER,
    // });
    dispatch(logoutUserAction());
}




/***
 *
 * Log user in from a specific token generated from the App Launcher
 *
 * @author Dylan Sadnick <dsadnick@edanalytics.org>
 * @param values
 * @return {Promise<void>}
 */
export async function loginUserFromToken({values, dispatch, environment}: UserInterfaceDispatch): Promise<void> {
    //Verify Token
    const tokenValid = await verifyToken(values.token, environment);
    if (tokenValid) {
        setUserCredentials({values, dispatch, environment});
        history.push("/");
    } else {
        clearUserCredentials(dispatch);
        history.push("/pageNotFound");
    }
}

/**
 * Verify that the token in the user reducer is valid to the app launcher it came from.
 *
 * @author Dylan Sadnick <dsadnick@edanalytics.org>
 * @param token
 * @return {Promise<boolean>}
 */
export async function verifyToken(token: string | null, environment: string): Promise<boolean | undefined> {
    const API: string | undefined = process.env.REACT_APP_API;

    try {
        axios.defaults.headers.common["AUTHORIZATION"] = token;
        axios.defaults.headers.common["ENVIRONMENT"] = environment;
        const data = await axios.post(`${API}/auth/verify_token`);
        return data.data.status === 'ok';
    } catch (error) {
        console.log(error)
    }
}

/***
 *
 * This will set the user credentials via a cookie and a reducer call.
 *
 * @author Dylan Sadnick <dsadnick@edanalytics.org>
 * @param user
 */
function setUserCredentials({values, dispatch, environment}: UserInterfaceDispatch): void {
    axios.defaults.headers.common["AUTHORIZATION"] = values.token;
    axios.defaults.headers.common["ENVIRONMENT"] = environment;
    const cookieObject: UserInterface = {
        "email": values.email,
        "token":values.token,
        "environment": environment,
    };
    cookie.save("user",cookieObject, {path: '/'});
    dispatch(loginUserAction(cookieObject));
    // dispatch({
    //     type: LOGIN_USER, payload: {
    //         "email": user.email,
    //         "token": user.token,
    //     }
    // });
}

