import React, {FC, useEffect, useState} from 'react';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import {ClickAwayListener, Drawer} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {makeStyles, ThemeProvider} from "@material-ui/core/styles";
import {AppActions, DrawerAnchor, MakeStyles, UseStateBoolean} from '../Helpers/GlobalTypes';
import {useDispatch, useSelector} from "react-redux";
import {getMenuBar} from "../actions/MenuBar/MenuBar";
import MenuBarStyle from "../assets/ts/MenuBar/MenuBarStyle";
import {Dispatch} from "redux";
import {getEmbedLink} from "../actions/IFrame/IFrame";
import {RootState} from "../store";

/**
 * Creates the menu bar and drawer.
 *
 * */
export const MenuBar: FC = (): JSX.Element => {
    const classes: MakeStyles = makeStyles(theme => (MenuBarStyle(theme)))();
    const [toggleOpen, setToggleOpen]: UseStateBoolean = useState<boolean>(true);
    const drawerPosition: DrawerAnchor = 'left';
    const dispatch: Dispatch<AppActions> = useDispatch();
    const {
        iframe: {
            link
        },
        menuBar: {
            embedArray,
        },
        user: {
            user,
        },
    }: RootState = useSelector((state: RootState) => state);

    useEffect(() => {
            getMenuBar({user, link, dispatch});
        }
        , [user])

    /**
     * Click handlers for menu icon.
     * */
    const handleClickAway = (): void => {
        setToggleOpen(false);
    };

    const handleOnClick = (): void => {
        setToggleOpen(!toggleOpen);
    };

    return (
        <ThemeProvider theme={{
            overrides:{
                MuiBackdrop:{
                    root:{
                        backgroundColor: link ? 'rgba(0, 0, 0, 0.5)' :  'none'
                    }
                }
            }
        }}>

        <Grid container direction={'column'}>
            <Grid item xs={12}>
                <AppBar
                    position="static"
                    className={classes.appBarColor}
                    id={"appBarID"}
                >

                    <Toolbar>
                        {embedArray && embedArray?.length > 0 &&
                        <ClickAwayListener onClickAway={handleClickAway}>
                            <IconButton edge="start" color="inherit" aria-label="menu"
                                        onClick={() => handleOnClick()}>
                                <MenuIcon/>
                            </IconButton>
                        </ClickAwayListener>}
                    </Toolbar>
                </AppBar>
            </Grid>
            {embedArray && embedArray?.length > 0 &&
            <Grid item>
                <React.Fragment key={drawerPosition}>
                    <Drawer anchor={drawerPosition} open={toggleOpen} variant={"temporary"}>
                        {Object.values(embedArray).map((val: any) => {
                            return (
                                <Grid container className={classes.drawer} justify={'center'}
                                      onClick={() =>
                                          // setCurrentSelection({embedObject: val, dispatch})
                                          getEmbedLink({user: user, embedObject: val, dispatch})
                                      }>
                                    <Typography className={classes.drawerText}>
                                        {val.EMBED_NAME}
                                    </Typography>
                                </Grid>
                            )
                        })
                        }
                    </Drawer>
                </React.Fragment>
            </Grid>}
        </Grid>
        </ThemeProvider>
    );
};
