import {GET_EMBED_LINK} from "../../actions/IFrame/IFrame";
import {IFrameState, IFrameActionTypes} from "../../Helpers/types/actions/IFrame";

const initialState: IFrameState = {};

export default function iframe(state = initialState, action: IFrameActionTypes): IFrameState {
    switch (action.type) {
        case GET_EMBED_LINK:
            return {
                ...state,
                link: action.payload.link,
                title: action.payload.title,
            };

        default:
            return state;
    }
}