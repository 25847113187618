import {Theme} from "@material-ui/core";
import {backgroundBlue} from "../GlobalStyle";

const IFrameStyle = (theme: Theme, appBarHeight: number): any => ({
    root: {
        backgroundColor: backgroundBlue,
        position: 'fixed',
        width: '100%',
        height: '100%',
        color: "#FFFFFF"
    },
    iframe: {
        display: 'block',
        width: '100%',
        height: window.innerHeight - appBarHeight,
        border: 'none',

    },
    selectionItem:{
        fontSize: 30,
    }
});

export default IFrameStyle;