import {Theme} from "@material-ui/core";

const LoadingStyle = (theme: Theme): any => ({
    dialog: {
        zIndex: 2,
    },
    dialogContent: {
        width: 300,
        height: 200,
    },
    loadingDiv: {
        width: '100%',
        textAlign: 'center',
        fontSize: 24,
        paddingTop: 20
    },
});

const dialogOverride: object = {
    overrides: {
        MuiDialog: {
            paperScrollPaper: {
                flexDirection: 'row'
            }
        }
    }
}

export default LoadingStyle;

export {
    dialogOverride
}