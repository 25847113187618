import React from "react";
import {Route, Redirect} from "react-router-dom";
import {useSelector} from "react-redux";
import {ProtectedRouteInterface} from "../Helpers/types/ProtectedRoutes";
import {RootState} from "../store";


export default function ProtectedRoute({exact, path, history, component}: ProtectedRouteInterface): JSX.Element {
    const {
        user: {
            user
        }
    }: RootState = useSelector((state: RootState) => state);
    if (user.token) {
        return (
            <Route exact={exact} path={path} history={history} component={component}/>
        )
    } else {
        return (

            <Redirect to={{pathname: '/pageNotFound'}}/>
        )
    }
}