import {SET_MENU_BAR} from "../../actions/MenuBar/MenuBar";
import {MenuBarActionTypes, MenuBarState} from "../../Helpers/types/actions/MenuBar";

const initialState: MenuBarState = {
    embedArray: undefined,
};

export default function menuBar(state = initialState, action: MenuBarActionTypes): MenuBarState {
    switch (action.type) {
        case SET_MENU_BAR:
            return {
                ...state,
                embedArray: action.payload
            }
        default:
            return state;
    }
}