import React, {FC} from "react";
import {Route, Switch} from 'react-router-dom';
import {IFrame} from "../IFrame/IFrame";
import ProtectedRoute from "../ProtectedRoutes/ProtectedRoutes";
import Token from "../Login/Token";
import {PageNotFound} from "../PageNotFound/PageNotFound";


interface Props {
    history: any
}

export const Main: FC<Props> =({history}) =>{

    return(
        <div>
            <Switch>

                <ProtectedRoute exact path={"/"} history={history} component={IFrame}/>
                <Route exact path={"/token/:token/:environment"} component={Token} history={history}/>

                {/*<Route exact path={"/"} history={history} component={IFrame}/>*/}

                {/*<ProtectedRoute exact={true} path={"/"} history={history} component={IFrame}/>*/}
                {/*<Route exact path={"/login"} history={history} component={Login}/>*/}

                {/*<Route exact path={"/401"} render={() => {*/}
                {/*    dispatch(logoutUserAction());*/}
                {/*    cookie.remove('user');*/}
                {/*    return (*/}
                {/*        <PageNotFound />*/}
                {/*    )}}/>*/}
                <Route exact path={"/pageNotFound"} component={PageNotFound}/>

                <Route component={PageNotFound}/>

            </Switch>
        </div>
    )
}