import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {createMuiTheme} from "@material-ui/core";
import store from "./store";
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom'
import history from './history'
import {ThemeProvider} from '@material-ui/core/styles'
require('dotenv').config()
const theme = createMuiTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 320,  //Customized sm=320-767
            md: 768, //Customized  md=768-1023 mdDown 1023
            lg: 1024, //Customized lg=1024-1499
            xl: 1500, //Customized xl >1500
        }
    }
})

// mobile < 768  xs to md is mobile
// tablet 768 - 1023
// desktop >=1024
// Max application size = 1500

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Router history={history}>
                <ThemeProvider theme={theme}>
                    <App/>
                </ThemeProvider>
            </Router>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
