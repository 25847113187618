import React, {FC, useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import {logoutUserAction} from "../actions/ActionCreators/User";
import cookie from "react-cookies";
import {Dispatch} from "redux";
import {AppActions, MakeStyles} from "../Helpers/GlobalTypes";
import {useDispatch} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import PageNotFoundStyle from "../assets/ts/PageNotFound/PageNotFoundStyle";

export const PageNotFound: FC = (): JSX.Element => {
    const dispatch: Dispatch<AppActions> = useDispatch();
    const classes: MakeStyles = makeStyles(theme => (PageNotFoundStyle(theme)))();

    useEffect(()=>{
        dispatch(logoutUserAction());
        cookie.remove('user');
    }, [])

return(
    <Grid container className={classes.root} direction={'column'} justify={'center'} alignItems={'center'}>
        <Grid item>
            Page Not Found.
        </Grid>
        {/*<Grid item>*/}
        {/*    Please login and try again.*/}
        {/*</Grid>*/}
    </Grid>
)
}